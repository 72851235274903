import * as types from '../types';
import Tmls from '../../api/tmls';

 export const getProducts = () => async dispatch => { 
    try {
          dispatch({type: types.LOADING})
        const products = await Tmls.get('/client');
        dispatch({type: types.PRODUCTS , payload: products.data})
      } catch (err) {
        dispatch({type: types.ERROR, payload: {
          type: 'productserror',
          data: err.response.data
        }});
      } 
                                        
        }

        export const searchproduct = (keyword) => async dispatch => { 
          try {
                dispatch({type: types.LOADING})
              const products = await Tmls.post('/cart/search', {search: keyword});
              dispatch({type: types.PRODUCTS , payload: products.data})
            } catch (err) {
              dispatch({type: types.ERROR, payload: {
                type: 'productserror',
                data: err.response.data
              }});
            } 
                                              
              }

        export const getCatalogs = () => async dispatch => { 
          try {
                dispatch({type: types.LOADING})
              const products = await Tmls.get('/category');
              dispatch({type: types.PRODUCTCATALOGS , payload: products.data})
            } catch (err) {
              dispatch({type: types.ERROR, payload: {
                type: 'productserror',
                data: err.response.data
              }});
            } 
                                              
              }

              export const sendMessage = (data) => async dispatch => { 
                try {
                      dispatch({type: types.LOADINGMESSAGE, payload: true})
                    const products = await Tmls.post('/specialorder/create', data);
                    dispatch({type: types.MESSAGE , payload: products.data})
                  } catch (err) {
                    dispatch({type: types.LOADINGMESSAGE, payload: false})
                    dispatch({type: types.ERROR, payload: {
                      type: 'messageerror',
                      data: err?.response?.data || 'error occured , try again'
                    }});
                  } 
                                                    
                    }


              export const Cart = (cart) => dispatch => {
                dispatch({type: types.CART, payload: cart});
              }

              export const clearCart = () => dispatch => {
                dispatch({type: types.CLEARCART});
              }

              export const clearError = () => dispatch => {
                dispatch({type: types.CLEARERROR});
              }

              export const submitcart = (data) => async dispatch => { 
                try {
                      dispatch({type: types.LOADINGCART})
                    const products = await Tmls.post('/cart/create', data);
                    dispatch({type: types.CARTSUCCESS , payload: products.data})
                  } catch (err) {
                    dispatch({type: types.ERROR, payload: {
                      type: 'productssubmiterror',
                      data: err?.response?.data || "error"
                    }});
                  } 
                                                    
                    }
                
                    export const getCategories = () => async dispatch => { 
                      try {
                            dispatch({type: types.LOADING})
                          const products = await Tmls.get('/subcategory');
                          dispatch({type: types.PRODUCTSUBCATEGORY , payload: products.data})
                        } catch (err) {
                          dispatch({type: types.ERROR, payload: {
                            type: 'productcategoryerror',
                            data: err.response.data
                          }});
                        } 
                                                          
                          }