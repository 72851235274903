import React from 'react';




const Pagination = ({postsperpage, totalposts, paginate, currentpage, back, next}) => {
    const pagenumbers = [];

    for(let i = 1; i <= Math.ceil(totalposts / postsperpage); i++){
        pagenumbers.push(i);
    }

    return (
        <nav>
        <ul className="pagination">
        <li>
       {currentpage <= 1 ? null :  <span onClick={() => back()} aria-label="Previous">
        <span aria-hidden="true">&laquo;</span>
        </span>}
        </li>
       {pagenumbers.slice(currentpage - 1, currentpage + 14).map(number => (
            <li className={currentpage === number ? "active" : null} key={number}><span onClick={() => paginate(number)}>{number}</span></li>
       ))}
        <li>
        {currentpage === Math.ceil(totalposts / postsperpage) ? null : <span onClick={() => next()} aria-label="Next">
        <span aria-hidden="true">&raquo;</span>
        </span>}
        </li>
        </ul>
        </nav>
    )

}



export default Pagination;