import React from 'react'
import { Switch, Route } from 'react-router-dom'
import { routes } from './constants'
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

const renderRoute = ({ component, path, ...route }) => {
  const Comp = component
  return (
    <Route
      {...route}
      exact
      path={path}
      component={props => <Comp {...props} />}
    />
  )
}

const Routes = () => <Switch>{routes.map(renderRoute)}</Switch>

export default Routes
