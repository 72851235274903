import First from '../components/Homepage';
import About from '../components/about';
import Services from '../components/services';
import Products from '../components/products';
import Cart from '../components/cart';
import Contactus from '../components/contactus';

export const routes = [
  {
    key: 'home',
    path: '/',
    component: First,
  },
  {
    key: 'about',
    path: '/about',
    component: About,
  },
  {
    key: 'services',
    path: '/services',
    component: Services,
  },
  {
    key: 'products',
    path: '/products',
    component: Products,
  },
  {
    key: 'cart',
    path: '/cart',
    component: Cart,
  },
  {
    key: 'contactus',
    path: '/contactus',
    component: Contactus,
  },
]
