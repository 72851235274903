
import React, { useEffect } from 'react';
import Layout from '../layout';
import OwlCarousel from 'react-owl-carousel';
import { Helmet } from 'react-helmet';
import $ from 'jquery';
window.jQuery = $;



function Homepage() {

    useEffect(() => {

    }, [])

    const opt =  {
        loop: true,
        autoplay: true,
        margin: 10,
        nav: false,
        dots: false,
        responsive: {
            0: {
                items: 1,
            },
            600: {
                items: 3,
            },
            1000: {
                items: 4,
            },
        },
    }


  const op = {
    nav : false, // Show next and prev buttons
    slideSpeed : 400,
    pagination : false,
    singleItem:true,
    autoPlay: true,
    navigation : true,
    items: 1,
    dots: false,
     navigationText: [
  "<i className='fa fa-angle-left'></i>",
  "<i className='fa fa-angle-right'></i>"
  ] ,

  }
  return (
   <Layout>
        <Helmet>
        <title>About | Total Med-Lab Solutions</title>
      </Helmet>
        <section id="page-header" className="parallax">
               <div className="overlay"></div>
                <div className="container">
                    <h1>ABOUT US</h1>
                   
                </div>
            </section>
            <section id="why-choose" className="section" style={{paddingBottom: "0px !important", marginBottom: '0px !important', backgroundColor: '#eeeeee'}}>
            <div className="container">
        <div className="row">
        <div className="col-md-12 margin-bottom-0">
        <div className="tab1-features" style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', width: '100%', position: 'relative', marginLeft: '0px !important'}}>
                    {/* <div className="icon"> <i className="fa fa-star-o"></i> </div> */}
                    <div style={{marginLeft: '0px !important'}}>
                        <h4 style={{textAlign: 'center', fontSize: 25}}>Company Overview</h4>
                        <p style={{textAlign: 'center', maxWidth: '80%', margin: '0 auto', fontSize: 18}}>Total Med-Lab Solutions is a private company
established in 2011. The company deals with supply
of high quality Medical, Teaching, Scientific Research chemicals and equipment at affordable cost,
coupled with technical support repairs and services of the same. The company gives a strong
emphasis on quality parameters of its supplies and delivery at timely manner as suggested by our
esteemed clientele.</p>
                    </div>
                </div>
        </div>
        </div>
        </div>  
            </section>
 <section id="why-choose" className="section" style={{paddingBottom: "0px !important", backgroundColor: '#fff'}}>
    <div className="container">
        <div className="row">
        {/* <div className="col-md-12 margin-bottom-30">
        <div className="tab1-features" style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', width: '100%', position: 'relative'}}> */}
                    {/* <div className="icon"> <i className="fa fa-star-o"></i> </div> */}
                    {/* <div className="info">
                        <h4 style={{textAlign: 'center'}}>Company Overview</h4>
                        <p style={{textAlign: 'center', maxWidth: '70%', margin: '0 auto'}}>Total Med-Lab Solutions is a private company
established in 2011. The company deals with supply
of high quality Medical, Teaching, Scientific Research chemicals and equipment at affordable cost,
coupled with technical support repairs and services of the same. The company gives a strong
emphasis on quality parameters of its supplies and delivery at timely manner as suggested by our
esteemed clientele.</p>
                    </div>
                </div>
        </div> */}
              <div className="col-md-6 margin-bottom-30">
                {/* <div className="tab1-features">
                    <div className="icon"> <i className="fa fa-star-o"></i> </div>
                    <div className="info">
                        <h4>Company Overview</h4>
                        <p>Total Med-Lab Solutions is a private company
established in 2011. The company deals with supply
of high quality Medical, Teaching, Scientific Research chemicals and equipment at affordable cost,
coupled with technical support repairs and services of the same. The company gives a strong
emphasis on quality parameters of its supplies and delivery at timely manner as suggested by our
esteemed clientele.</p>
                    </div>
                </div> */}
                
                <div className="tab1-features">
                   
                    <div className="info">
                        <h4>Vision</h4>
                        <p>“Our aim is to be the preferred supplier in the supply of quality laboratory chemicals, laboratory equipment’s and service of laboratory scientific machines for Hospitals, Scientific Research Centers, Scientific Laboratories Centers, Mining Centers, and other scientific institutions in the local and international markets. Upholding our commitment to innovation and being competitive for our customers.”</p>
                    </div>
                </div>
              
                <div className="tab1-features">
                    
                    <div className="info">
                        <h4>Mision</h4>
                        <p>“We are striving towards being the supplier of best grade of chemicals, quality machineries and a strong specialized technical team for repair and servicing of laboratories, teaching, research and medical equipment in particular”</p>
                    </div>
                </div>

                <div className="tab1-features">
                    <div className="info">
                        <h4>Values</h4>
                        <p>Integrity, Respect, Responsibility</p>
                    </div>
                </div>
                
            </div>
 
         <div className="col-md-6">
            <div className="tab-carousel">
            <OwlCarousel classNameName='owl-theme' {...op}> 
                <div className="item"><img src="https://res.cloudinary.com/dedfrilse/image/upload/v1628933074/work-min.jpg" alt=""/></div>
                {/* <div className="item"><img src="images/works/img5.jpg" alt=""/></div>
                <div className="item"><img src="images/works/img6.jpg" alt=""/></div>		 */}
                </OwlCarousel>				 
          </div>
         </div>
           
        </div>
    </div>
</section>

<section className="section" style={{paddingTop: "30px !important", backgroundColor: '#eeeeee'}}>
    <div className="container">
        <div className="row">
            <div className="title-box text-center">
                <h2 className="title">LEGAL</h2>
             </div>
             <div class="clients-carousel">
                   <OwlCarousel className='owl-theme' loop {...opt}>  
                        <div class="item">
                            <a href="/">
                                <figure style={{width: 250, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                    <img src="https://res.cloudinary.com/dedfrilse/image/upload/v1635151812/msafirifinal/TIN.png" alt="" />
                                </figure>
                            </a>
                        </div>
                   
                        <div class="item">
                            <a href="/">
                                <figure style={{width: 250, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                      <img src="https://res.cloudinary.com/dedfrilse/image/upload/v1635151812/msafirifinal/ZPPDA.png" alt="" />
                                </figure>
                            </a>
                        </div>
                        
                
                        <div class="item">
                            <a href="/">
                                <figure  style={{width: 250, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                      <img src="https://res.cloudinary.com/dedfrilse/image/upload/v1635151810/msafirifinal/GCLA.png" alt="" />
                                </figure>
                            </a>
                        </div>
                   
                        {/* <div class="item">
                            <a href="/">
                                <figure style={{width: 250, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                      <img src="https://res.cloudinary.com/dedfrilse/image/upload/v1630475336/msafirifinal/TVLA.png" alt="" />
                                </figure>
                            </a>
                        </div> */}
            
                        <div class="item">
                            <a href="/">
                                <figure style={{width: 250, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                      <img src="https://res.cloudinary.com/dedfrilse/image/upload/v1635151809/msafirifinal/BRELA.png" alt="" />
                                </figure>
                            </a>
                        </div>
                        
          
                        <div class="item">
                            <a href="/">
                                <figure style={{width: 250, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                     <img src="https://res.cloudinary.com/dedfrilse/image/upload/v1635151809/msafirifinal/VAT.png" alt="" />
                                </figure>
                            </a>
                        </div>
                        <div class="item">
                            <a href="/">
                                <figure style={{width: 250, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                     <img src="https://res.cloudinary.com/dedfrilse/image/upload/v1635151807/msafirifinal/GPSA.png" alt="" />
                                </figure>
                            </a>
                        </div>
                        <div class="item">
                            <a href="/">
                                <figure style={{width: 250, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                     <img src="https://res.cloudinary.com/dedfrilse/image/upload/v1635151805/msafirifinal/CGCLA.png" alt="" />
                                </figure>
                            </a>
                        </div>
                        <div class="item">
                            <a href="/">
                                <figure style={{width: 250, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                     <img src="https://res.cloudinary.com/dedfrilse/image/upload/v1635151801/msafirifinal/OSHA.png" alt="" />
                                </figure>
                            </a>
                        </div>
                        </OwlCarousel>
		         </div>
        </div>
    </div>
</section>   

<section id="team" className="section" style={{paddingTop: "0px !important"}}>
		<div className="container">
            <div className="row">         
             <div className="title-box text-center">
                <h2 className="title">Management</h2>
             </div>
             </div>
			
             <div style={{backgroundColor: "#fff", width: "100%", marginTop: '-100px', marginBottom: '-50px'}}>
                <div className="container">
                <div className="servicegrid" style={{marginTop: "0 !important", marginBottom: "0 !important"}}>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="core-features">   
                                <p>We have a team of staffs who are highly experienced and talented to work in diverse professionals: All the team members are extremely trained and experienced with past exposure in the corporate world as distinguished professionals.</p>     
                                <p>Our teams have confidence with handling any background irrespective of the complexity linked to such a task. We have a balanced team of professionals simplifying all projects that we handle.</p>     
                                <p>Our team maintains a subtle accessibility and working relationship which guarantee our clients full-time attention whenever they need our help.</p>      
                      </div>
                       </div>            
                         <div className="col-md-6">
                    <div className="devices-image">
                        <img src="https://res.cloudinary.com/dedfrilse/image/upload/v1628933313/worktwo-min.jpg" alt=""/>
                    </div>
                </div>
                </div>
                  
                </div>
                </div>
            </div>
			</div>
        
	</section>
   </Layout>
  );
}

export default Homepage;