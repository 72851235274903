import $ from 'jquery';
import React, { useEffect, useState } from 'react';
import Layout from '../layout';
import { useDispatch, useSelector } from 'react-redux';
import {getCatalogs, getProducts, searchproduct, Cart, getCategories} from '../store/actions/appactions';
import { useLocation } from "react-router-dom";
import Pagination from './pagination';
import Loader from "react-loader-spinner";
import {Helmet} from 'react-helmet';
import _ from 'lodash';
window.jQuery = $;


function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function Homepage() {
const dispatch = useDispatch();
const app = useSelector(state => state.app);
const [client, setClient] = useState('');
const [orders, setOrders] = useState([]);
const [show, setShow] = useState(false);
const [showdesc, setShowdesc] = useState(false);
const [description, setDescription] = useState('');
const [cat, setCat] = useState("");
let query = useQuery();
const keyword = query.get("keyword");
const [currentpage, setCurrentpage] = useState(1);
const [postperpage, setPostperpage] = useState(5);
const [catalogs, setCatalogs] = useState([]);
const [error, setError] = useState(false);


useEffect(() => {
   if(client){
     const catlog = _.filter(app?.catalogs, { client: {"_id": client?._id}});
     setCatalogs(catlog)
   }
}, [client])

    useEffect(() => {
      if(keyword){
        setCurrentpage(1);
       dispatch(searchproduct(keyword))
       dispatch(getCategories());
      }else{
        setCurrentpage(1);
    dispatch(getProducts());
    dispatch(getCategories());
      }
    
    }, [keyword, dispatch]);

    useEffect(() => {
       if(show){
         setError(false);
      window.$('#exampleModal').modal('show')
       }else{
         setError(false);
        window.$('#exampleModal').modal('hide')
       }
    }, [show])

    useEffect(() => {
      if(showdesc){
     window.$('#description').modal('show')
      }else{
       window.$('#description').modal('hide')
      }
   }, [showdesc])

    const filterproduct = (product) => {
       setCat(product._id);
       dispatch(searchproduct(product.title.toLowerCase()))
    }

  const duplicate = app?.cart?.find(car => car?.id === client?._id) || false;

  const onSave = () => {
   if(duplicate){
     setError(true)
   }else{
     setError(false);
    const order = {
      id: client._id,
      title: client.title,
      brand: client.brand,
      packages: orders
    }
    let cartitems = app.cart;
    cartitems.push(order)
    dispatch(Cart(cartitems));
    setShow(false);
    setClient("");
    setOrders([]);
   }
  }




  const indexoflatpost = currentpage * postperpage;
  const indexoffirstpost = indexoflatpost - postperpage;
  const currentposts = app?.products?.slice(indexoffirstpost, indexoflatpost) || [];
const prod = app?.products || [];
  const paginate = number => setCurrentpage(number);
  
  const back = () => {
    setCurrentpage(currentpage - 1);
  }

  const next = () => {
    setCurrentpage(currentpage + 1);
  }

  const openmodal = (product) => {
    setError(false);
    setClient(product);
    setShow(true)
  }


  return (
   <Layout>
      <Helmet>
        <title>Products | Total Med-Lab Solutions</title>
      </Helmet>
 	<section id="page-header" className="parallax">
           <div className="overlay"></div>
			<div className="container">
				<h1>PRODUCTS</h1>
			</div>
		</section>

        <section className="section">
			<div className="container">
				<div className="row">
                    <div className="col-md-4 col-sm-12">
                        <div className="widget-main">
                            <h4>Categories</h4>
                           <div  className="post-categories">
                               <ul style={{listStyleType: 'none !important'}}>
                               {app?.subcategories ? app.subcategories.map(sub => {
                                 return (
                                  <li style={{display: 'flex', flexDirection: 'row', alignItems: 'center', listStyleType: 'none'}} key={sub._id}> 
                                   <input value={cat} checked={cat === sub._id} onClick={() => {
                                     filterproduct(sub)
                                   }} type="checkbox" aria-label="..."/>
                                  <span style={{marginLeft: '5px'}}>{sub.title}</span>
                                  </li>
                                 )
                               }) : null}
                              </ul>
                            </div>
                       </div>
                    </div>
								
				   <div className="col-md-8 col-sm-12">	
                    
                       <div className="widget widget-sidebar">
							
						</div>
                      
  <div className="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div className="modal-dialog" role="document">
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title" id="exampleModalLabel">Packages</h5>
          <button type="button" className="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <div className="row">
          {error ?  <div className='col-md-12'>
           <div className="alert alert-warning alert-dismissible" role="alert">
  <strong>warning!</strong>  this product is already on cart, in order to add this product you have to clear it from the cart items.
</div>
               </div> : null}
              {catalogs ? catalogs.map(catalog => {
                return (
                  <div className="col-md-6">
                  <div className="form-group" style={{display: 'flex', flexDirection: 'column'}}>
                    <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                    <span>
                      <input checked={orders.find(order => order._id === catalog._id)} onClick={() => {
                        if(orders.find(order => order._id === catalog._id)){
                          const remove = orders.filter(order => order._id !== catalog._id);
                          setOrders(remove);
                        }else{
                          setOrders([...orders, catalog])
                        }
                      }} type="checkbox" style={{width: "15px", height: "15px"}} aria-label="..."/>
                    </span>
                      <label for="fullname" style={{fontSize: "16px", marginLeft: "5px", marginBottom: '0 !important', fontWeight: 400,}}><span style={{fontSize: 12, fontWeight: 400, lineHeight: catalog?.packagename === '.' ? 0 : 2}}>{catalog.catalognumber}</span><br/>{catalog?.packagename && catalog?.packagename === '.' ? null : catalog.packagename}</label>
                      </div>
                      <input onChange={(e) => {
                        const remove = orders.filter(order => order._id !== catalog._id);
                        const product = orders.find(prod => prod._id === catalog._id);
                        if(product){
                          let prod = product
                          const quantity = e.target.value;
                          prod.quantity = quantity
                          setOrders([...remove, prod])
                        }
                      }} value={orders.find(prod => prod._id === catalog._id)?.quantity || null}  style={{color: "#202020 !important", border: "0.5px solid #fefefe", borderColor: "#bdbdbd", height: 40 }} type="text" className="form-control" id="fullname" placeholder="quantity"/>
                    </div>  
                 </div>
                )
              }) : null}
          </div>
           
        </div>
        <div className="modal-footer">
          <button onClick={() => onSave()}  type="button" className="btn btn-primary" style={{color: "#fff"}}>Add to cart</button>
        </div>
      </div>
    </div>
  </div>

  <div className="modal fade" id="description" tabindex="-1" role="dialog" aria-labelledby="description" aria-hidden="true">
    <div className="modal-dialog" role="document">
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title" id="exampleModalLabel">Description</h5>
          <button type="button" className="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <span>{description}</span>
        </div>
        <div className="modal-footer">
          <button onClick={() => setShowdesc(false)}  type="button" className="btn btn-primary" style={{color: "#fff"}}>close</button>
        </div>
      </div>
    </div>
  </div>
				
						<div className="widget-main">
           {app.loading ? 
           <div style={{width: '100%', height: 200, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
           <Loader
           type="Puff"
           color="#00BFFF"
           height={100}
           width={100}
           timeout={3000} //3 secs
            />
           </div> : currentposts ? currentposts.map(product => {
             const word = product?.description || '';
               return (
                <div className="sidebar-widget clearfix">	
                <a href="#" style={{height: 120, width: 150,backgroundColor: '#eeeeee'}}>
                  <img src={product?.brand?.title === 'MRS' ? 'https://res.cloudinary.com/dedfrilse/image/upload/v1628938683/MRS.jpg' :product?.brand?.title === 'LOBA' ? 'https://res.cloudinary.com/dedfrilse/image/upload/v1634715734/LOBA_Logo.png' : product?.brand?.title === 'ISO-LAB' ? 'https://res.cloudinary.com/dedfrilse/image/upload/v1634715865/ISO-LAB_Logo.png' : 'https://res.cloudinary.com/dedfrilse/image/upload/v1629265782/msafirifinal/flaskgrey.jpg' } alt="" style={{height: 120, width: 150, display: 'block', objectFit: 'contain'}}/></a>
                <p className="sidebar-widget-title">{product?.title?.length > 70 ? `${product?.title?.substring(0, 70)}...` : product?.title}</p>
                <p className="date">Brand: {product?.brand?.title}</p>
                <a style={{cursor: 'pointer'}} data-toggle="modal" data-target="#description" onClick={() => {setDescription(word); setShowdesc(true)}}><p className="sidebar-widget-title">{word.length > 140 ? `${word.substring(0, 140)}...` : word}</p></a>
                <button onClick={() => {openmodal(product)}} data-toggle="modal" data-target="#exampleModal" type="button" className="btn btn-primary submit-button" >ORDER NOW</button>
                </div>     
               )
           }) : null}                       
								</div>
						<div>
          {app?.products?.length > 5 ? <Pagination back={back} next={next} postsperpage={postperpage} totalposts={prod.length} paginate={paginate} currentpage={currentpage}/> : null}
            </div>
					</div>
				
				</div> 			
			</div>		
		</section>

   </Layout>
  );
}

export default Homepage;