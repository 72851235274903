
import React, { useEffect } from 'react';
import Layout from '../layout';
import {Helmet} from 'react-helmet';
import $ from 'jquery';
window.jQuery = $;



function Homepage() {

    useEffect(() => {

    }, [])


  return (
   <Layout>
        <Helmet>
        <title>Services | Total Med-Lab Solutions</title>
      </Helmet>
       	<section id="page-header" className="parallax">
           <div className="overlay"></div>
			<div className="container">
				<h1>SERVICES</h1>
			</div>
		</section>

        <div style={{backgroundColor: "#eeeeee", width: "100%"}}>
    <div className="container">
    <div className="servicegrid">
        <div className="row">
            <div className="col-md-6">
                <div className="core-features">   
                    <h2 className="title" style={{fontSize: 25, fontWeight: 'bold', textTransform: 'capitalize'}}>After Sale Services</h2>        
                    <p>We help and ensure that a customer gets as much use and value as possible out of their purchase. This may entail training on existing features or education about new uses or capabilities. The business reasoning behind after-sales support is that it can contribute to brand loyalty and repeat sales; happier customers tend to create repeat customers. Good after-sales services can also result in positive word-of-mouth for a company. Poor after-sales support can prevent companies from achieving a good customer satisfaction record and therefore growth.</p>      
                    <h2 className="title" style={{fontSize: 25, fontWeight: 'bold', textTransform: 'capitalize'}}>Consultancy</h2>           
                    <p>We offer services of advisory and intellectual nature provided by our consultants using their professional skills to architectural services, supervision, social and environmental assessments, technical assistance, and program implementation.</p>  
                    
          </div>
           </div>      
           <div className="col-md-6">
               <div className="devices-image">
                   <img src="https://res.cloudinary.com/dedfrilse/image/upload/v1628935285/consultancy.jpg" alt=""/>
               </div>
           </div>
          
        </div>
    </div>
</div>
</div>

<div style={{backgroundColor: "#fff", width: "100%", marginBottom: '50px'}}>
    <div className="container">
    <div className="servicegrid">
        <div className="row">
             <div className="col-md-6">
        <div className="devices-image">
            <img src="https://res.cloudinary.com/dedfrilse/image/upload/v1628932866/work.jpg" alt=""/>
        </div>
    </div>
     <div className="col-md-6">
        <div className="core-features">   
            <h2 className="title" style={{fontSize: 25, fontWeight: 'bold', textTransform: 'capitalize'}}>Machine Repair</h2>          
            <p>We have several years of experience in maintenance, servicing and general repairs of machinery and equipment. As specialists in maintenance, services, and among other areas, we can offer a short waiting time and consulting and repairing services by our professionally qualified team.</p>  
            <h2 className="title" style={{fontSize: 25, fontWeight: 'bold', textTransform: 'capitalize'}}>Installation & Ommision</h2>          
            <p>We offer different type of equipment and machine installation to our clients on site and ensure highest safety of use. We also provide our clients with proper safety manuals to ensure sustainability and environmental friendliness of their operations. We also provide omission services including equipment maintenance, migration and troubleshooting.</p>       

            
  </div>
   </div>            
    </div>
      
    </div>
    </div>
</div>


   </Layout>
  );
}

export default Homepage;