export const SUCCESS_USERINFO = 'SUCCESS_USERINFO';
export const LOADING = 'LOADING';
export const ERROR = 'ERROR';
export const SUCCESS_MODIFY = 'SUCCESS_MODIFY';
export const SUCCESS_REVIEW = 'SUCCESS_REVIEW';
export const CLEARERROR = 'CLEARERROR';
export const CLEARSUCCESS = 'CLEARSUCCESS';
export const CONFIRMLOADING = 'CONFIRMLOADING';
export const SUCCESS_CONFIRM = 'SUCCESS_CONFIRM';
export const PRODUCTS = 'PRODUCTS';
export const PRODUCTCATALOGS = 'PRODUCTCATALOGS';
export const CART = 'CART';
export const CARTSUCCESS = 'CARTSUCCESS';
export const LOADINGCART = 'LOADINGCART';
export const CLEARCART = 'CLEARCART';
export const PRODUCTSUBCATEGORY = 'PRODUCTSUBCATEGORY';
export const MESSAGE = 'MESSAGE';
export const LOADINGMESSAGE = 'LOADINGMESSAGE';