import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {Link} from "react-router-dom";
import { useLocation } from 'react-router-dom';


function Layout(props) {
const app = useSelector(state => state.app);
const locations = useLocation();
const [keyword, setKeyword] = useState('');
const [show, setShow] = useState(false);

 useEffect(() => {
  if(show){
    window.openNav();
  }else if(show === false){
    window.closeNav();
  }
 }, [show])

  return (
  <>
    <div id="flipkart-navbar">
    <div className="container">
        <img src="./images/logo.jpg" className="logoimg" alt='logo'/>
        <div className="row row2">
            <div className='col-sm-2'></div>
            <div className="col-sm-12 smallest" style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between'}}>
                <div>
                <h2 style={{margin: "0"}}><span className="smallnav menu" onClick={() => setShow(true)}>☰</span></h2>
                <h1 style={{margin: "0"}}><span className="largenav"></span></h1>
                </div>
                <div>
                <Link className="cart-button" to='/cart'>
                    <svg className="cart-svg " width="16 " height="16 " viewBox="0 0 16 16 ">
                        <path d="M15.32 2.405H4.887C3 2.405 2.46.805 2.46.805L2.257.21C2.208.085 2.083 0 1.946 0H.336C.1 0-.064.24.024.46l.644 1.945L3.11 9.767c.047.137.175.23.32.23h8.418l-.493 1.958H3.768l.002.003c-.017 0-.033-.003-.05-.003-1.06 0-1.92.86-1.92 1.92s.86 1.92 1.92 1.92c.99 0 1.805-.75 1.91-1.712l5.55.076c.12.922.91 1.636 1.867 1.636 1.04 0 1.885-.844 1.885-1.885 0-.866-.584-1.593-1.38-1.814l2.423-8.832c.12-.433-.206-.86-.655-.86 " fill="#007bff"></path>
                    </svg>
                  {app?.cart?.length > 0 ?  <span className="item-number ">{app?.cart?.length}</span> : null} 
                </Link>
                </div>
            </div>
            <div className="flipkart-navbar-search smallsearch col-sm-9 col-xs-11">
                <div className="row">
                    <input value={keyword} onChange={(e) => setKeyword(e.target.value)} className="flipkart-navbar-input col-xs-11" type="" placeholder="Search by Product name, Brand name" name=""/>
                    <Link to={{
         pathname: "/products",
         search: `?${keyword ? `&keyword=${keyword}` : ''}`,
    }}>   <button className="flipkart-navbar-button col-xs-1">
                        <svg width="15px" height="15px">
                            <path d="M11.618 9.897l4.224 4.212c.092.09.1.23.02.312l-1.464 1.46c-.08.08-.222.072-.314-.02L9.868 11.66M6.486 10.9c-2.42 0-4.38-1.955-4.38-4.367 0-2.413 1.96-4.37 4.38-4.37s4.38 1.957 4.38 4.37c0 2.412-1.96 4.368-4.38 4.368m0-10.834C2.904.066 0 2.96 0 6.533 0 10.105 2.904 13 6.486 13s6.487-2.895 6.487-6.467c0-3.572-2.905-6.467-6.487-6.467"></path>
                        </svg>
                    </button></Link>
                </div>
            </div>
            <div className="cart largenav col-sm-1">
                <Link className="cart-button" to='/cart'>
                    <svg className="cart-svg " width="16 " height="16 " viewBox="0 0 16 16 ">
                        <path d="M15.32 2.405H4.887C3 2.405 2.46.805 2.46.805L2.257.21C2.208.085 2.083 0 1.946 0H.336C.1 0-.064.24.024.46l.644 1.945L3.11 9.767c.047.137.175.23.32.23h8.418l-.493 1.958H3.768l.002.003c-.017 0-.033-.003-.05-.003-1.06 0-1.92.86-1.92 1.92s.86 1.92 1.92 1.92c.99 0 1.805-.75 1.91-1.712l5.55.076c.12.922.91 1.636 1.867 1.636 1.04 0 1.885-.844 1.885-1.885 0-.866-.584-1.593-1.38-1.814l2.423-8.832c.12-.433-.206-.86-.655-.86 " fill="#007bff"></path>
                    </svg>
                  {app?.cart?.length > 0 ?  <span className="item-number ">{app?.cart?.length}</span> : null} 
                </Link>
            </div>
        </div>
        <div className="row row1">
            <ul className="largenav">
                <li className={locations.pathname === '/' ? "upper-links active" : "upper-links"}><Link className="links" to="/">Home</Link></li>
                <li className={locations.pathname === '/about' ? "upper-links active" : "upper-links"}><Link className="links" to="/about">About</Link></li>
                <li className={locations.pathname === '/services' ? "upper-links active" : "upper-links"}><Link className="links" to="/services">Services</Link></li>
                <li className={locations.pathname === '/products' ? "upper-links active" : "upper-links"}><Link className="links" to="/products">Products</Link></li>
                <li className={locations.pathname === '/contactus' ? "upper-links active" : "upper-links"}><Link className="links" to="/contactus">Contact Us</Link></li>
            </ul>
        </div>
    </div>
</div>
<div id="mySidenav" className="sidenav">
    <div className="container" style={{backgroundColor: '#fff', paddingTop: '10px'}}>
        <span className="sidenav-heading"></span>
        <span className="closebtn" onClick={() => setShow(false)}>×</span>
    </div>
    <a href="/">Home</a>
    <a href="/about">About us</a>
    <a href="/services">Services</a>
    <a href="/products">Products</a>
    <a href="contactus">Contact</a>
</div>
           {props.children}
   <footer>
    <div className="container">
         <div className="row">
           <div className="col-md-4 col-sm-12 col-xs-12">
                <div className="footer-links">
                    <h1 style={{color: "#007bff", fontSize: '20px', fontWeight: '800'}}>TOTAL MED-LAB SOLUTIONS</h1>
                    <p style={{color: '#202020', textAlign: 'left'}}>We are the leading suppliers of high quality Medical, Teaching, Scientific Research chemicals and equipment at affordable cost, coupled with technical support repairs and services of the same.</p>
                 </div>  
            </div>
            <div className="col-md-2 col-sm-12 col-xs-12">
                <div className="footer-links">
                    <h2 style={{color: "#202020"}}>USEFUL LINKS</h2>
                    <ul>
                     <li><a href="/" style={{textDecoration: 'none', color: '#202020'}}><span>Home</span></a></li>
                     <li> <a href="/about"><span>About us</span></a></li>
                     <li><a href="/services"><span>Services</span></a></li>
                     <li><a href="/products"><span>Products</span></a></li>
                    <li><a href="/contactus"><span>Contact Us</span></a></li>
                    </ul>
                 </div>  
            </div>
            <div className="col-md-3 col-sm-12 col-xs-12">
                 <div className="footer-links">
                     <h2 style={{color: "#202020"}}>CONTACT US</h2>
                     <ul>
                      <li><a href="/" style={{textDecoration: 'none', color: "#202020"}}><span>Bint. Matola street, Ada Estate,</span></a></li>
                      <li> <a href="/about.html"><span>Kinondoni, Dar-Es-Salaam.</span></a></li>
                      <li><a href="/services.html"><span>P. O. Box 33374, Dar es Salaam, Tanzania.</span></a></li>
                      <li><a href="/products.html"><span> Mob: +255 713 631 074</span></a></li>
                     <li><a href="/contactus.html"><span>Tel: +255 22 2667 627/8</span></a></li>
                     <li><a href="/contactus.html"><span>Email: info@tmls.co.tz</span></a></li>
                     </ul>
                  </div>  
             </div>
             <div className="col-md-3 col-sm-12 col-xs-12">
                <div className="footer-links">
                    <h2 style={{color: "#202020"}}>OUR PROMISE</h2>
                    <p style={{color: "#202020", textAlign: 'left'}}>We are always committed towards safety of our operations and activities, thereby minimizing their impact on the environment.</p>
                 </div>  
            </div>
       
             <div className="col-md-6 col-sm-12 col-xs-12">
                <div className="copyright"><p>Copyright @ 2021 TMLS All Rights Reserved | Designed by: <a href="https://tujengetech.co.tz">Tujenge Technologies Ltd</a>
              </p></div>
            </div>
            <div className="col-md-6 col-sm-12 col-xs-12">
                 <div className="social-icons">
                     <ul>
                      <li><a href="/"><i className="fa fa-facebook"></i></a></li>
                      <li> <a href="/"><i className="fa fa-twitter"></i></a></li>
                      <li><a href="/"><i className="fa fa-google-plus"></i></a></li>
                      <li><a href="/"><i className="fa fa-instagram"></i></a></li>
                     </ul>
                  </div>
             </div>
             </div>
             </div>
   </footer>
   {/* <a href="#" className="scrollup"> <i className="fa fa-chevron-up"> </i> </a> */}
   </>
   
  )
}

export default Layout;

// <div className="container">
//            <div className="row">
//             <div className="col-md-4 col-sm-12 col-xs-12">
//                 <div className="footer-links">
//                     <h1 style={{color: "#007bff", fontSize: '20px', fontWeight: '800'}}>TOTAL MED-LAB SOLUTIONS</h1>
//                     <p style={{color: '#202020', textAlign: 'left'}}>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Earum quo saepe ut soluta, sit pariatur, sint quasi facere harum quod amet ipsum. Eum voluptate molestiae consectetur iure reprehenderit similique rerum quas qui voluptatum, animi,</p>
//                  </div>  
//             </div>
//             <div className="col-md-2 col-sm-12 col-xs-12">
//                 <div className="footer-links">
//                     <h2 style={{color: "#202020"}}>USEFUL LINKS</h2>
//                     <ul>
//                      <li><a href="/" style={{textDecoration: 'none', color: '#202020'}}><span>Home</span></a></li>
//                      <li> <a href="/about.html"><span>About us</span></a></li>
//                      <li><a href="/services.html"><span>Services</span></a></li>
//                      <li><a href="/products.html"><span>Products</span></li>
//                     <li><a href="/contactus.html"><span>Contact Us</span></li>
//                     </ul>
//                  </div>  
//             </div>
//             <div className="col-md-3 col-sm-12 col-xs-12">
//                 <div className="footer-links">
//                     <h2 style={{color: "#202020"}}>CONTACT US</h2>
//                     <ul>
//                      <li><a href="/" style={{textDecoration: 'none', color: "#202020"}}><span>Bint. Matola street, Ada Estate, Kinondoni, Dar-Es-Salaam.</span></a></li>
//                      <li> <a href="/about.html"><span>P. O. Box 33374, Dar es Salaam, Tanzania.</span></a></li>
//                      <li><a href="/services.html"><span>Mob: 0713 631 074</span></a></li>
//                      <li><a href="/products.html"><span> Tel: 0222667627/8</span></li>
//                     <li><a href="/contactus.html"><span>Email: info@tmls.co.tz</span></li>
//                     </ul>
//                  </div>  
//             </div>
//             <div className="col-md-3 col-sm-12 col-xs-12">
//                 <div className="footer-links">
//                     <h2 style={{color: "#202020"}}>OUR NEWSLETTER</h2>
//                     <p style={{color: "#202020", textAlign: 'left'}}>Lorem ipsum dolor sit amet consectetur adipisicing elit. Minima repellat nihil non obcaecati fugit ipsam vitae, debitis temporibus id ullam.</p>
//                  </div>  
//             </div>
       
//              <div className="col-md-6 col-sm-12 col-xs-12">
//                 <div className="copyright"><p>Copyright @ 2021 TMLS All Rights Reserved | Designed by: <a href="https://tujengetech.co.tz">Tujenge Technologies Ltd</a>
//               </p></div>
//             </div>
//             <div className="col-md-6 col-sm-12 col-xs-12">
//                 <div className="social-icons">
//                     <ul>
//                      <li><a href="#"><i className="fa fa-facebook"></i></a></li>
//                      <li> <a href="#"><i className="fa fa-twitter"></i></a></li>
//                      <li><a href="#"><i className="fa fa-google-plus"></i></a></li>
//                      <li><a href="#"><i className="fa fa-instagram"></i></a></li>
//                     </ul>
//                  </div>
//             </div>

//            </div> 
//        </div>
