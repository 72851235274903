
import React, { useEffect, useState } from 'react';
import Layout from '../layout';
import {Helmet} from 'react-helmet';
import $ from 'jquery';
import { useDispatch, useSelector } from 'react-redux';
import { Cart, clearCart, submitcart} from '../store/actions/appactions';
window.jQuery = $;



function Homepage() {
const dispatch = useDispatch();
const app = useSelector(state => state.app);
const [phonenumber, setPhonenumber] = useState("");
const [fullname, setFullname] = useState("");
const [email, setEmail] = useState("");
const [company, setCompany] = useState("");
const [details, setDetails] = useState("");
const [error, setError] = useState(false);


useEffect(() => {
   dispatch(clearCart())
}, [dispatch])

useEffect(() => {
  if(app.successcart){
      setError(false);
      setFullname('');
      setPhonenumber("");
      setEmail("");
      setCompany("");
      setDetails("");
      dispatch(Cart([]));
  }
}, [app.successcart, dispatch])


const onDelete = (cartid) => {
    let cartitems = app.cart;
    const filter = cartitems.filter(cart => cart.id !== cartid)
    dispatch(Cart(filter));
}

    const submitdata = () => {
        setError(false);
        if(phonenumber && fullname && email && company && details && app?.cart?.length > 0){
            const data = {
                phonenumber,
                fullname,
                email,
                company,
                details,
                products: app.cart
            }
            dispatch(submitcart(data));
        }else{
            setError(true)
        }
    }

  
  return (
   <Layout>
        <Helmet>
        <title>Cart | Total Med-Lab Solutions</title>
      </Helmet>
 	<section id="page-header" className="parallax">
               <div className="overlay"></div>
                <div className="container">
                    <h1>CART</h1>
                </div>
            </section>
   
   
       <div className="container" style={{paddingTop: '100px', paddingBottom: '100px'}}>
           <div className="row">  
    <div className="col-md-6">
        <div className="row">
       {app.successcart ?  <div className='col-md-12'>
           <div className="alert alert-success alert-dismissible" role="alert">
  <button type="button" className="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">&times;</span></button>
  <strong>success!</strong>your order is submitted successfully.
</div>
               </div>  : null}
               {app?.error?.type === 'productssubmiterror' ?  <div className='col-md-12'>
           <div className="alert alert-danger alert-dismissible" role="alert">
  <button type="button" className="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">&times;</span></button>
  <strong>error!</strong>an error occurred please try again.
</div>
               </div> : null}

               {error ?  <div className='col-md-12'>
           <div className="alert alert-danger alert-dismissible" role="alert">
  <button type="button" className="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">&times;</span></button>
  <strong>error!</strong>please fill all the required fields and add items to cart
</div>
               </div> : null}
            <form>
              <div className="col-md-6">
               <div className="form-group">
                   <label for="fullname">Full name</label>
                   <input value={fullname} onChange={(e) => setFullname(e.target.value)} style={{color: "#202020 !important", border: "0.5px solid #fefefe", borderColor: "grey"}} type="text" className="form-control" id="fullname" placeholder="full name"/>
                 </div>  
              </div>
              <div className="col-md-6">
                 <div className="form-group">
                   <label for="phone">Phone number</label>
                   <input value={phonenumber} onChange={(e) => setPhonenumber(e.target.value)} type="text" style={{color: "#202020 !important", border: "0.5px solid #fefefe", borderColor: "grey"}} className="form-control" id="phone" placeholder="Phone number"/>
                 </div>
              </div>
              <div className="col-md-6">
                 <div className="form-group">
                 <label for="exampleFormControlInput1">Email address</label>
                 <input value={email} onChange={(e) => setEmail(e.target.value)} style={{color: "#202020 !important", border: "0.5px solid #fefefe", borderColor: "grey"}} type="email" className="form-control" id="exampleFormControlInput1" placeholder="name@example.com"/>
               </div>
              </div>
              <div className="col-md-6">
                  <div className="form-group">
                   <label for="companyname">Company name</label>
                   <input value={company} onChange={(e) => setCompany(e.target.value)} type="text" style={{color: "#202020 !important", border: "0.5px solid #fefefe", borderColor: "grey"}} className="form-control" id="companyname" placeholder="Company name"/>
                 </div>
              </div>
              <div className="col-md-12">
                <div className="form-group">
                 <label for="exampleFormControlTextarea1">Additional details</label>
                 <textarea value={details} onChange={(e) => setDetails(e.target.value)}  style={{color: "#202020 !important", border: "0.5px solid #fefefe", borderColor: "grey"}} className="form-control" id="exampleFormControlTextarea1" rows="3"></textarea>
               </div> 
              </div> 
              <div className="col-md-9"></div>
              <div className="col-md-3"> <button onClick={() => {
                  submitdata()
              }} type="button" className="btn btn-primary" style={{color: "#fff"}}>{app.loadingcart ? "submitting..." : "Submit"}</button></div>
             </form>
         </div>
           </div> 
           <div className="col-md-6">
          {app?.cart && app?.cart?.length > 0 ? app.cart.map(item => {
              return (
                  <>
                <div className="cartitem">
                <div className="cartphotoandwords">
                    <div className="cartimage">
                  <img src={item?.brand?.title === 'MRS' ? 'https://res.cloudinary.com/dedfrilse/image/upload/v1628938683/MRS.jpg' :item?.brand?.title === 'LOBA' ? 'https://res.cloudinary.com/dedfrilse/image/upload/v1634715734/LOBA_Logo.png' : item?.brand?.title === 'ISO-LAB' ? 'https://res.cloudinary.com/dedfrilse/image/upload/v1634715865/ISO-LAB_Logo.png' : 'https://res.cloudinary.com/dedfrilse/image/upload/v1629265782/msafirifinal/flaskgrey.jpg' } alt="" style={{height: 100, width: 150, display: 'block', objectFit: 'contain'}}/>
                    </div>
                    <div className="cartwords">
                    <span className="carthead">Product name: {item.title}</span>
                    <span className="supplier">Brand: {item?.brand?.title || null}</span>
                    <span className="supplier">Package:  {item?.packages?.map((pack) => {
                   return (
                     <span>{`${pack.catalognumber || ''} x ${pack?.quantity || ''}, `}</span>
                   )
                 })}</span>
                    </div>
                </div>
                <div className="removebutton">
                    <span style={{fontSize: "20px", color: "black"}}>
                    <span style={{cursor: 'pointer', color: "#202020"}} onClick={() => {onDelete(item.id)}}><i className="fa fa-trash"></i></span>
                    </span>
                    
                </div>
               </div>
               <div className="divider"></div>
               </>
              )
          }) : app?.cart?.length === 0 ? <div style={{width: '100%', height: 300, display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: '#eeeeee', borderRadius: 5}}>
              <span style={{fontWeight: 'bold'}}>Cart is empty, please add items to place an order &#x1F6D2;</span>
          </div> : null}
            </div>
           </div> 
           </div>      
   

   </Layout>
  );
}

export default Homepage;