import * as types from '../types';

const initialState = {
  loading: false,
  error: null,
  userinfo: null,
  cart: []
}

export const appReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.CLEARSUCCESS:
      return {
        ...state,
        modifysuccess: null,
        feedback: null,
        successconfirm: null
      }
    case types.CLEARERROR:
      return {
        ...state,
        loading: false,
        error: null,
        message: null
      }
    case types.SUCCESS_REVIEW:
      return {
        ...state,
        feedback: action.payload,
        loading: false,
        error: null
      }
    case types.SUCCESS_MODIFY:
      return {
        ...state,
        modify: action.payload,
        loading: false,
        error: null,
        modifysuccess: true
      }
    case types.SUCCESS_USERINFO:
      return{
        ...state,
        userinfo: action.payload.data,
        token: action.payload.token,
        sessionName: action.payload.sessionName,
        type: action.payload.type,
        error: null,
        loading: false
      }
      case types.SUCCESS_CONFIRM:
        return{
          ...state,
          successconfirm: action.payload,
          modify: action.payload,
          error: null,
          confirmloading: false,
          loading: false
        }
    case types.LOADING:
      return {
        ...state,
        loading: true,
        error: null
      }
    case types.CONFIRMLOADING:
      return {
        ...state,
        confirmloading: true,
        error: null
        }
    case types.ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
        loadingcart: false
      }
    case types.PRODUCTS:
      return {
        ...state,
        loading: false,
        products: action.payload
      }
    case types.PRODUCTCATALOGS:
      return {
        ...state,
        loading: false,
        catalogs: action.payload
      }
    case types.CART:
      return {
        ...state,
        loading: false,
        cart: action.payload
      }
    case types.LOADINGCART:
      return {
        ...state,
        loadingcart: true,
      }
    case types.CARTSUCCESS: 
    return {
      ...state,
      successcart: true,
      loadingcart: false,
    }
    case types.CLEARCART:
      return {
        ...state,
        successcart: false,
        loadingcart: false,
        error: null
      }
    case types.PRODUCTSUBCATEGORY:
      return {
        ...state,
        subcategories: action.payload
      }
    case types.LOADINGMESSAGE:
      return {
        ...state,
        loadingmessage: action.payload
      }
    case types.MESSAGE:
      return {
        ...state,
        loadingmessage: false,
        message: action.payload
      }
    default:
      return state
  }
}



