
import React, { useEffect } from 'react';
import Layout from '../layout';
import OwlCarousel from 'react-owl-carousel';
import { useDispatch } from 'react-redux';
import { Helmet } from 'react-helmet';
import {getCatalogs} from '../store/actions/appactions';
import $ from 'jquery';
window.jQuery = $;



function Homepage() {
    const dispatch = useDispatch();

    useEffect(() => {
    dispatch(getCatalogs());
    }, [])

  const opt =  {
        loop: true,
        autoplay: true,
        margin: 10,
        nav: false,
        dots: false,
        responsive: {
            0: {
                items: 1,
            },
            600: {
                items: 3,
            },
            1000: {
                items: 6,
            },
        },
    }

  const op = {
    nav : false, // Show next and prev buttons
    slideSpeed : 400,
    pagination : false,
    singleItem:true,
    autoplay: true,
    navigation : true,
    items: 1,
    dots: false,
     navigationText: [
  "<i className='fa fa-angle-left'></i>",
  "<i className='fa fa-angle-right'></i>"
  ] ,

  }
  return (
   <Layout>
        <Helmet>
        <title>Total Med-Lab Solutions | Tanzania's Leading Supplier of Scientific and Medical Chemicals and Equipment</title>
      </Helmet>
        <div id="homebanner" className="homebanner">
            <img src="https://res.cloudinary.com/dedfrilse/image/upload/v1628931410/tmlseq-min.jpg" alt="homebanner" className='bannerimage'/>
            <div className="bannerwords">
                <div className="container-fluid bannerinside">
                <h1>HOME OF SCIENTIFIC EXPLORATION</h1>
                <p>Total Med-Lab Solutions is always committed<br/>towards safety of its operations and activities,<br/> thereby minimizing their impact on the environment.</p>
                <a type="button" href='/products' className="btn btn-primary" style={{borderRadius: "5px !important", color: "#fff", backgroundColor: "#007bff !important"}}>View Products</a>
                </div>
            </div>
        </div>
        <section id="clients" className="section partners" style={{padding: "30px 0 30px !important", backgroundColor: "#fff"}}>
    <div className="container">
        <div className="row">
        
     <div className="title-box text-center" style={{marginBottom: "50px !important"}}>
         <h2 className="title">Our Partners</h2>
      </div>
      <div className="clients-carousel">  
      <OwlCarousel classNameName='owl-theme' autoplay={true} loop {...opt}>  
      <div className="item">
                            <a href="/">
                                <figure style={{height: 150, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                    <img src="https://res.cloudinary.com/dedfrilse/image/upload/v1634715865/sigma_aldich.png" alt="" />
                                </figure>
                            </a>
                        </div>
                   
                        <div className="item">
                            <a href="/">
                                <figure style={{height: 150, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                      <img src="https://res.cloudinary.com/dedfrilse/image/upload/v1634715865/ISO-LAB_Logo.png" alt="" />
                                </figure>
                            </a>
                        </div>
                        
                
                        <div className="item">
                            <a href="/">
                                <figure  style={{height: 150, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                      <img src="https://res.cloudinary.com/dedfrilse/image/upload/v1628938683/MRS.jpg" alt="" />
                                </figure>
                            </a>
                        </div> 
                 <div className="item">
                     <a href="/">
                         <figure style={{height: 150, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                              <img src="https://res.cloudinary.com/dedfrilse/image/upload/v1634715735/OXOID_Logo.png" alt="" />
                         </figure>
                     </a>
                 </div>   
                 <div className="item">
                            <a href="/">
                                <figure  style={{height: 150, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                      <img src="https://res.cloudinary.com/dedfrilse/image/upload/v1634715735/chemlab_LOGO.png" alt="" />
                                </figure>
                            </a>
                        </div> 
                        <div className="item">
                            <a href="/">
                                <figure  style={{height: 150, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                      <img src="https://res.cloudinary.com/dedfrilse/image/upload/v1634715735/TM_MEDIA_Logo.png" alt="" />
                                </figure>
                            </a>
                        </div> 
                        <div className="item">
                            <a href="/">
                                <figure  style={{height: 150, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                      <img src="https://res.cloudinary.com/dedfrilse/image/upload/v1634715734/LOBA_Logo.png" alt="" />
                                </figure>
                            </a>
                        </div> 
                        <div className="item">
                            <a href="/">
                                <figure  style={{height: 150, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                      <img src="https://res.cloudinary.com/dedfrilse/image/upload/v1634715735/PYREX_Logo.png" alt="" />
                                </figure>
                            </a>
                        </div> 
                 </OwlCarousel>
                 </div>
        </div> 
  </div> 
</section>

  <section  id="about" class="section" style={{backgroundColor: "#eeeeee"}}>
    <div class="title-box text-center">
        <h2 class="title">OUR SERVICES</h2>
     </div>

       <div class="container">
           <div class="row">
              
              
              <div class="col-md-6">
                  <div class="features-icon-box">
                  
                      <div class="features-icon">
                      <i class="fa fa-wrench"></i>
                      </div>
                      
                      <div class="features-info">
                         <h4>After Sale Services</h4>
                         <p>We help and ensure that a customer gets as much use and value as possible out of their purchase.</p>
                      </div>
                  </div>
              </div>
 
           
              <div class="col-md-6">
                  <div class="features-icon-box">
                  
                      <div class="features-icon">
                      <i class="fa fa-cogs"></i>
                      </div>
                      
                      <div class="features-info">
                         <h4>Machine Repair</h4>
                         <p>We have over 10 years of experience in maintenance, servicing and general repairs of machinery and equipment.</p>
                      </div>
                  </div>
              </div>

              <div class="col-md-6">
                  <div class="features-icon-box">
                  
                      <div class="features-icon">
                     <i class="fa fa-lightbulb-o"></i>
                      </div>
                      
                      <div class="features-info">
                         <h4>Consultancy</h4>
                         <p>We offer services of advisory and intellectual nature provided by our consultants using their professional skills</p>
                      </div>
                  </div>
              </div>
 
       
              <div class="col-md-6">
                  <div class="features-icon-box">
                  
                      <div class="features-icon">
                      <i class="fa fa-wrench"></i>
                      </div>
                      
                      <div class="features-info">
                         <h4>Installation and Omission</h4>
                         <p>We offer different type of equipment and machine installation to our clients on site and ensure highest safety of use.</p>
                      </div>
                  </div>
              </div> 
           </div> 
       </div> 
  </section> 

 <section class="sectiontwo" style={{backgroundColor: '#fff'}}>
    <div class="title-box text-center">
        <h2 class="title">OUR PRODUCTS</h2>
     </div>
      <div class="container">
          <div class="row"> 
                <div class="col-md-12">
					<div class="content-tab-1">
						<ul class="nav">
							<li class="active">
								<a aria-expanded="true" href="#tab-content-1" data-toggle="tab">
								<i class="fa fa-desktop"></i>
								<h4>Consumables</h4>
								</a>
								<div class="tab-arrow">
								</div>
							</li>
                            
							  <li>
								<a aria-expanded="true" href="#tab-content-2" data-toggle="tab">
								<i class="fa fa-flask"></i>
								<h4>Chemicals</h4>
								</a>
								<div class="tab-arrow">
								</div>
							</li>
                            
							<li>
								<a aria-expanded="true" href="#tab-content-3" data-toggle="tab">
								<i class="fa fa-institution"></i>
								<h4>Equipments</h4>
								</a>
								<div class="tab-arrow">
								</div>
							</li>
						</ul>
                        
						<div class="tab-content-main">
								<div class="container">
									<div class="tab-content">
										<div class="tab-pane active in" id="tab-content-1">
											
                                       	
                                            <div class="col-md-6 margin-bottom-30">
                                                <div class="tab1-features">
                                                    
                                                    <div class="info">
                                                        <p>We deal with supplying of laboratory consumables of high quality and standards. Our products are manufactured by world leading manufacturers in the space of consumables manufacturing.</p>
                                                    </div>
                                                </div>
                                                
                                                <div class="tab1-features">
                                                    <div class="info">
                                                        <p>We supply different laboratory consumables for different sectors such as education, health and also for research and development space. We provide consumable office supplies such as office papers, Rim papers, pens, file folders, Post-it notes, and toner or ink cartridges. We deliver the items to your business location with highest quality and standards from high profile manufacturers.</p>
                                                    </div>
                                                </div>
                                            </div>
                                          
                                            
                                           
                                         
                                          <div class="col-md-6">
                                              <div class="tab-carousel">
                                              <OwlCarousel className='owl-theme' loop {...op}>
                                                    <div class="item"><img src="https://res.cloudinary.com/dedfrilse/image/upload/v1628937662/istockphoto-92400341-612x612.jpg" alt=""/></div>
                                                   
                                                    </OwlCarousel>						 
                                              </div>
                                          </div>
                                                                             
                                            
										</div>
                                      
										<div class="tab-pane" id="tab-content-2">
                                            <div class="tab-pane" id="tab-content-2">
											 
                                                <div class="col-md-4">
                                                     <div class="tab2-services-box">
                                                         <div class="media">
                                                             <img src="https://res.cloudinary.com/dedfrilse/image/upload/v1628938003/E-min.jpg"  alt="services"/>
                                                         </div>
                                                         
                                                         <div class="services-info">
                                                             <h4>MEDICAL PURPOSES</h4>
                                                             <p>We supply different chemicals and reagents for medical purposes with highest quality and standards manufactured by world leading manufacturers.</p>
                                                         </div>
                                                         
                                                     </div>
                                                </div>
                                                
                                               <div class="col-md-4">
                                                     <div class="tab2-services-box">
                                                         <div class="media">
                                                             <img src="https://res.cloudinary.com/dedfrilse/image/upload/v1628938002/F-min.jpg"  alt="services"/>
                                                         </div>
                                                         
                                                         <div class="services-info">
                                                             <h4>EDUCATION PURPOSE</h4>
                                                             <p>We supply different sets of chemicals and reagents for education purposes and laboratories in learning institutions such as secondary schools, universities and colleges of science studies.</p>
                                                         </div>
                                                         
                                                     </div>
                                                </div>
                                                
                                                <div class="col-md-4">
                                                     <div class="tab2-services-box">
                                                         <div class="media">
                                                             <img src="https://res.cloudinary.com/dedfrilse/image/upload/v1628938003/H-min.jpg"  alt="services"/>
                                                         </div>
                                                         
                                                         <div class="services-info">
                                                             <h4>RESEARCH PURPOSE</h4>
                                                             <p>We supply different sets of chemicals and reagents with purpose of conducting different scientific researches in laboratories, in medical institutions and in learning institutions.</p>
                                                         </div>
                                                         
                                                     </div>
                                                </div> 
                                                    
                                            </div>
										</div>
                                                               
										<div class="tab-pane" id="tab-content-3">
                                                
                                       
                                        
                                       
                                            <div class="features-tab3">
                                        
                                               
                                                <div class="tab-content-5">
                                                
                                                    <div class="col-md-6">
                                                         <div class="core-features">
                                                           
                                                             <p>We offer different type of equipment and machine installation to our clients on site and ensure highest safety of use. We also provide our clients with proper safety manuals to ensure sustainability and environmental friendliness of their operations. We also provide omission services including equipment maintenance, migration and troubleshooting.</p>


                                                             <p>We have over 10 years of experience in maintenance, servicing and general repairs of machinery and equipment. As specialists in maintenance, services, and among other areas, we can offer a short waiting time and consulting and repairing services by our professionally qualified team.</p>
                                                             
                                                       {/* <ul class="list">
                                                            <li>Font-Awesome Integration</li>
                                                            <li>Multiple unique designs</li>
                                                            <li>Clean coded, responsive and multipurpose</li>
                                                            <li>Pages valid on w3c.</li>
                                                            <li>Well documented.</li>
                                                            <li>Easy to  customization.</li>
                                                        </ul> */}
                                                             
                                                   </div>
                                                    </div>
                                                    
                                                    
                                                    <div class="col-md-6">
                                                        <div class="devices-image">
                                                            <img src="https://res.cloudinary.com/dedfrilse/image/upload/v1628933313/worktwo-min.jpg" alt=""/>
                                                        </div>
                                                    </div>
                                                    
                                                </div>
                                               
                                               
                                        
                                            </div> 
									</div>                                      
									</div>
								</div>
						  </div>
                     
					</div>
				</div>
              
            </div> 
      </div> 
  </section>

    <section id="clients" class="section">
           <div class="container">
               <div class="row">
               
            <div class="title-box text-center">
                <h2 class="title">Our Clients</h2>
             </div>
                
                   <div class="clients-carousel">
                   <OwlCarousel className='owl-theme' loop {...opt}>  
                        <div class="item">
                            <a href="/">
                                <figure style={{height: 150, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                    <img src="https://res.cloudinary.com/dedfrilse/image/upload/v1630475336/msafirifinal/udsm_logo.png" alt="" />
                                </figure>
                            </a>
                        </div>
                   
                        <div class="item">
                            <a href="/">
                                <figure style={{height: 150, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                      <img src="https://res.cloudinary.com/dedfrilse/image/upload/v1630475336/msafirifinal/AMSONS.jpg" alt="" />
                                </figure>
                            </a>
                        </div>
                        
                
                        <div class="item">
                            <a href="/">
                                <figure  style={{height: 150, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                      <img src="https://res.cloudinary.com/dedfrilse/image/upload/v1630475336/msafirifinal/1601305883-oryxlogo2ch.jpg" alt="" />
                                </figure>
                            </a>
                        </div>
                   
                        <div class="item">
                            <a href="/">
                                <figure style={{height: 150, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                      <img src="https://res.cloudinary.com/dedfrilse/image/upload/v1630475336/msafirifinal/TVLA.png" alt="" />
                                </figure>
                            </a>
                        </div>
            
                        <div class="item">
                            <a href="/">
                                <figure style={{height: 150, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                      <img src="https://res.cloudinary.com/dedfrilse/image/upload/v1630475336/msafirifinal/AZAM-LOGO.jpg" alt="" />
                                </figure>
                            </a>
                        </div>
                        
          
                        <div class="item">
                            <a href="/">
                                <figure style={{height: 150, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                     <img src="https://res.cloudinary.com/dedfrilse/image/upload/v1630475335/msafirifinal/total_png.png" alt="" />
                                </figure>
                            </a>
                        </div>
                        <div class="item">
                            <a href="/">
                                <figure style={{height: 150, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                     <img src="https://res.cloudinary.com/dedfrilse/image/upload/v1635151788/msafirifinal/GCLA_logo.png" alt="" />
                                </figure>
                            </a>
                        </div>
                        <div class="item">
                            <a href="/">
                                <figure style={{height: 150, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                     <img src="https://res.cloudinary.com/dedfrilse/image/upload/v1635151787/msafirifinal/Intertek_Logo.png" alt="" />
                                </figure>
                            </a>
                        </div>
                        <div class="item">
                            <a href="/">
                                <figure style={{height: 150, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                     <img src="https://res.cloudinary.com/dedfrilse/image/upload/v1635151787/msafirifinal/MUHAS.png" alt="" />
                                </figure>
                            </a>
                        </div>
                        <div class="item">
                            <a href="/">
                                <figure style={{height: 150, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                     <img src="https://res.cloudinary.com/dedfrilse/image/upload/v1635151787/msafirifinal/Udom.png" alt="" />
                                </figure>
                            </a>
                        </div>
                        <div class="item">
                            <a href="/">
                                <figure style={{height: 150, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                     <img src="https://res.cloudinary.com/dedfrilse/image/upload/v1635151787/msafirifinal/TMDA_Logo.png" alt="" />
                                </figure>
                            </a>
                        </div>
                        <div class="item">
                            <a href="/">
                                <figure style={{height: 150, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                     <img src="https://res.cloudinary.com/dedfrilse/image/upload/v1635151787/msafirifinal/ZFDA.png" alt="" />
                                </figure>
                            </a>
                        </div>
                        <div class="item">
                            <a href="/">
                                <figure style={{height: 150, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                     <img src="https://res.cloudinary.com/dedfrilse/image/upload/v1635151787/msafirifinal/ZBS.png" alt="" />
                                </figure>
                            </a>
                        </div>
                        </OwlCarousel>
		         </div>
               </div>
         </div>
    </section>


   </Layout>
  );
}

export default Homepage;