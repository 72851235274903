
import React, { useEffect, useState } from 'react';
import Layout from '../layout';
import {Helmet} from 'react-helmet';
import $ from 'jquery';
import { useDispatch, useSelector } from 'react-redux';
import { sendMessage,clearError } from '../store/actions/appactions';
window.jQuery = $;



function Homepage() {
const app = useSelector(state => state.app);
const dispatch = useDispatch();
const [fullname, setFullname] = useState('');
const [email, setEmail] = useState('');
const [subject, setSubject] = useState('');
const [message, setMessage] = useState('');

useEffect(() => {
clearError();
}, []);

const onSubmit = (e) => {
    e.preventDefault()
    dispatch(clearError());
    const data = {
        fullname,
        email,
        subject,
        message
    }
    dispatch(sendMessage(data));
}


  return (
   <Layout>
        <Helmet>
        <title>Contact | Total Med-Lab Solutions</title>
      </Helmet>
       <section id="page-header" className="parallax">
               <div className="overlay"></div>
                <div className="container">
                    <h1>CONTACT US</h1>
                </div>
            </section>
           
   
   
       <div className="container">
           <div className="row">
           
                 <div className="title-box text-center white">
                    <h2 style={{color: "#202020", marginTop: "50px"}} className="title"></h2>
                 </div>
             </div>

               <div className="col-md-8 col-md-offset-2 contact-form">
               
                    <div className="contact-info text-center">
                       <p style={{color: "#202020", fontWeight: 'bold'}}>Total Med-Lab Solutions</p>
                       <p style={{color: "#202020"}}>Bint. Matola street, Ada Estate, Kinondoni,</p>
                       <p style={{color: "#202020"}}>P. O. Box 33374, Dar es Salaam, Tanzania.</p>
                       <p style={{color: "#202020"}}>Mob: +255 713 631 074</p>
                       <p style={{color: "#202020"}}>Tel: +255 22 2667 627/8</p>
                       <p style={{color: "#202020"}}>Email: info@tmls.co.tz</p>
                    </div>
                     
                     <form onSubmit={(e) => {onSubmit(e)}} style={{marginBottom: 50}}>
                        <div className="row">
                            {app.message ? <div className='col-md-12'>
           <div className="alert alert-success alert-dismissible" role="alert">
  <button type="button" className="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">&times;</span></button>
  <strong>success!</strong>your message is submitted successfully.
</div>
               </div> : null}
               {app?.error?.type === 'messageerror' ?  <div className='col-md-12'>
           <div className="alert alert-danger alert-dismissible" role="alert">
  <button type="button" className="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">&times;</span></button>
  <strong>error!</strong>an error occurred please try again.
</div>
               </div> : null}
                            <div className="col-md-4">
                                <input value={fullname} onChange={(e) => setFullname(e.target.value)} style={{color: "#202020 !important", border: "0.5px solid #fefefe", borderColor: "grey" }} className="form-control" id="name" placeholder="Full Name" type="text"/>
                            </div>
                            <div className="col-md-4">
                                <input value={email} onChange={(e) => setEmail(e.target.value)} style={{color: "#202020 !important", border: "0.5px solid #fefefe", borderColor: "grey" }} className="form-control" id="email" placeholder="Your Email" type="email"/>
                            </div>
                            <div className="col-md-4">
                                <input value={subject} onChange={(e) => setSubject(e.target.value)} style={{color: "#202020 !important", border: "0.5px solid #fefefe", borderColor: "grey" }} className="form-control" id="subject" placeholder="Subject" type="text"/>
                            </div>
                            <div className="col-md-12">
                                <textarea value={message} onChange={(e) => setMessage(e.target.value)} style={{color: "#202020 !important", border: "0.5px solid #fefefe", borderColor: "grey" }} className="form-control" id="message" rows="7" placeholder="Your Message"></textarea>
                            </div>
                            <div className="col-md-12 text-right">
                                <button type="submit" className="btn btn-green">{app.loadingmessage ? "...submitting" : "SEND MESSAGE"}</button>
                            </div>
                        </div>
                    </form>
               </div>
              
       </div>

   </Layout>
  );
}

export default Homepage;